import React, { useState } from "react";
import { promptIdeas, promptIdeasTurkish } from "../data";
import PromptIdea from "./PromptIdea";
import { useTranslation } from "react-i18next";

function PromptIdeas({ ideas }: any) {
  const [numIdeasToShow, setNumIdeasToShow] = useState(10);
  const { t, i18n } = useTranslation();
  const lng = navigator.language;

  const handleShowMore = () => {
    setNumIdeasToShow(numIdeasToShow + 5);
  };

  const array =
    i18n.resolvedLanguage === "en" ? promptIdeas : promptIdeasTurkish;
  return (
    <div
      className="flex flex-col space-y-3 items-center justify-center mt-4"
      ref={ideas}
    >
      <h1 className="text-white lg:text-3xl font-semibold py-2 md:text-2xl sm:text-xl ">
        {t("pages.home.prompIdeas.header.title")}{" "}
      </h1>
      <div className="w-4/6 grid lg:grid-cols-3 gap-y-4 gap-x-8 md:grid-cols-2 sm:grid-cols-1">
        {array.slice(0, numIdeasToShow).map((idea) => (
          <PromptIdea idea={idea.idea} title={idea.title} key={idea.id} />
        ))}
      </div>
      {numIdeasToShow < promptIdeasTurkish.length && (
        <button
          className="text-white bg-gradient-to-r from-yellow-600 to-red-600 px-4 py-2 rounded-md hover:bg-blue-600 font-medium text-lg"
          onClick={handleShowMore}
        >
          {t("pages.home.prompIdeas.button.title")}
        </button>
      )}
    </div>
  );
}

export default PromptIdeas;
