import { createSlice } from "@reduxjs/toolkit";

interface userProductSliceState {
  selectedBrandName: null | string;
  selectedProductName: null | string;
  selectedProductCategory: null | string;
  selectedProduct: null | string;
}

const initialState: userProductSliceState = {
  selectedBrandName: null,
  selectedProductName: null,
  selectedProductCategory: null,
  selectedProduct: null,
};

export const userProductSlice = createSlice({
  name: "userProduct",
  initialState,
  reducers: {
    setProductToInput: (state, { payload }) => {
      state.selectedBrandName = payload.brandName;
      state.selectedProductName = payload.productName;
      state.selectedProductCategory = payload.productCategory;
    },
    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload;
    },
    setProductToNull: (state) => {
      state.selectedBrandName = null;
      state.selectedProductName = null;
      state.selectedProductCategory = null;
    },
  },
  extraReducers: (builder) => {},
});

export const { setProductToInput, setSelectedProduct, setProductToNull } =
  userProductSlice.actions;

export default userProductSlice.reducer;
