import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UserInformation from "../components/ProfilePage/UserInformation";
import axios from "axios";
import Cookies from "js-cookie";

function ProfilePage() {
  const prompter = useRef(null);
  const ideas = useRef(null);
  const token = Cookies.get("access_token");
  const [userInformation, setUserInformation] = useState() as any;

  const getUserInformation = async () => {
    const response = await axios.get("https://api.ceremeet.com/api/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.data.company !== null) {
      setUserInformation(response.data.data.company);
    } else {
      setUserInformation(response.data.data.user);
    }
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  return (
    <div className=" min-h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black flex flex-col justify-between ">
      <Navbar prompter={prompter} ideas={ideas} />
      <UserInformation userInformation={userInformation} />
      <Footer ideas={ideas} prompter={prompter} />
    </div>
  );
}

export default ProfilePage;
