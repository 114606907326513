import React, { useEffect, useState } from "react";
import {
  contentTypes,
  languagesEN,
  languagesTR,
  options,
  optionsNew
} from "../../data";
import TypingText from "./TypingText";
import axios from "axios";
import { log } from "console";
import Cookies from "js-cookie";
import Loading from "./Loading";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductToNull,
  setSelectedProduct
} from "../../redux/features/userProductsSlice";
import { useTranslation } from "react-i18next";
import {
  addProductToArray,
  createProduct
} from "../../redux/features/productListingSlice";

interface FormInputsProps {
  selectedTitle: string;
}

function FormInputs({ selectedTitle }: FormInputsProps) {
  const { t, i18n } = useTranslation();
  const lng = navigator.language;

  const BASE_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { selectedBrandName, selectedProductName, selectedProductCategory } =
    useSelector((state: RootState) => state.userProduct);
  const token = Cookies.get("access_token");

  const selectedObject = contentTypes.find((contentType) =>
    i18n.resolvedLanguage === "en"
      ? contentType.nameEN
      : contentType.nameTR === selectedTitle
  );
  const [error, setError] = useState<null | []>(null);
  const [inputValue, setInputValue] = useState("");
  const [brandName, setBrandName] = useState("");
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [competitor, setCompetitor] = useState("");
  const [targetCustomer, setTargetCustomer] = useState("");
  const [industry, setIndustry] = useState("");
  const [targetGroup, setTargetGroup] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [positioning, setPositioning] = useState("");
  const [holiday, setHoliday] = useState("");
  const [offer, setOffer] = useState("");
  const [framework, setFramework] = useState("");
  const [channel, setChannel] = useState("");
  const [loading, setLoading] = useState(false);
  const [aiResponse, setAiResponse] = useState<null | string>(null);
  const [promptMessage, setPromptMessage] = useState<null | string>(null);
  const [addToProduct, setAddToProduct] = useState(false);
  const [language, setLanguage] = useState("Turkish");

  useEffect(() => {
    setInputValue("");
    setBrandName("");
    setProductName("");
    setProductCategory("");
    setCompetitor("");
    setTargetCustomer("");
    setIndustry("");
    setTargetGroup("");
    setProductDetails("");
    setPositioning("");
    setHoliday("");
    setOffer("");
    setFramework("");
    setChannel("");
    setError(null);
    setAiResponse("");
    setPromptMessage("");
    dispatch(setSelectedProduct(null));
    dispatch(setProductToNull());
  }, [selectedTitle]);

  useEffect(() => {
    if (error) {
      setPromptMessage("");
      setAiResponse("");
    }
  }, [error]);

  useEffect(() => {
    if (selectedBrandName && selectedProductName && selectedProductCategory) {
      setBrandName(selectedBrandName);
      setProductName(selectedProductName);
      setProductCategory(selectedProductCategory);
    }
  }, [selectedBrandName, selectedProductName, selectedProductCategory]);

  function submitHandler(e: any) {
    e.preventDefault();
    generate();
  }

  async function generate() {
    setLoading(true);
    let response;
    setError(null);

    try {
      if (addToProduct === true) {
        createProduct({
          BASE_URL,
          token,
          productName,
          brandName,
          productCategory
        });
        dispatch(
          addProductToArray({
            id: Date.now(),
            name: productName,
            brandName: brandName,
            category: productCategory
          })
        );
      }
      if (
        selectedTitle === "Ana Sayfa Başlığı" ||
        selectedTitle === "Homepage Headline"
      ) {
        // if(brandName.length > 2 && productName.length > 2 && productCategory.length > 2 ){
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/homepageHeadline`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            industry: industry,
            productDetails: productDetails,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Ana Sayfa İçeriği" ||
        selectedTitle === "Homepage Content"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/homepageContent`,
          {
            brandName: brandName,
            industry: industry,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Hakkımızda Sayfası İçeriği" ||
        selectedTitle === "About us Content"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/aboutUs`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            industry: industry,
            competitor: competitor,
            positioning: positioning,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Koleksiyon Başlığı" ||
        selectedTitle === "Collection Title"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/collectionTitle`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Koleksiyon Açıklaması" ||
        selectedTitle === "Collection Description"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/collectionDescription`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Sık Sorulan Sorular" ||
        selectedTitle === "FAQ"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/faq`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            positioning: positioning,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Ürün Başlığı" ||
        selectedTitle === "Product Title"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/productTitle`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            industry: industry,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Ürün Açıklaması" ||
        selectedTitle === "Product Descriptions"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/productDescription`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetGroup: targetGroup,
            competitor: competitor,
            productDetails: productDetails,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Sayfa Meta Başlığı" ||
        selectedTitle === "Page Meta Title"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/pageMetaTitle`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            productDetails: productDetails,
            targetCustomer: targetCustomer,
            industry: industry,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Sayfa Meta Açıklaması" ||
        selectedTitle === "Page Meta Description"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/pageMetaDescription`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            industry: industry,

            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
        selectedTitle === "Product Page Meta Description"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/productPageMetaDescription`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            industry: industry,

            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Ürün Sayfası Meta Başlığı" ||
        selectedTitle === "Product Page Meta Title"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/productPageMetaTitle`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            industry: industry,

            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "E-posta Konusu" ||
        selectedTitle === "Email Subject Line"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/emailSubjectLine`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,

            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "E-posta İçeriği" ||
        selectedTitle === "Email Content"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/emailContent`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,

            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (selectedTitle === "SMS" || selectedTitle === "SMS Message") {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/smsMessage`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Whatsapp Mesajı" ||
        selectedTitle === "Whatsapp Message"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/whatsappMessage`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Tarayıcı Bildirimi" ||
        selectedTitle === "Browser Notification"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/browserNotification`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Uygulama Bildirimi" ||
        selectedTitle === "App Notification"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/appNotification`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Reklam Başlığı" ||
        selectedTitle === "Ad Caption"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/adCaption`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language,
            channel: channel
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Reklam Metni" ||
        selectedTitle === "Ad Copy"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/adCopy`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            channel: channel,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (
        selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
        selectedTitle === "Social Media Page Description"
      ) {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/socialMediaPageDescription`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            industry: industry,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            channel: channel,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (selectedTitle === "Makale" || selectedTitle === "Article") {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/article`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            industry: industry,
            competitor: competitor,
            positioning: positioning,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else if (selectedTitle === "Twitter") {
        response = await axios.post(
          `${BASE_URL}/api/ai/ecommerce/twitter`,
          {
            brandName: brandName,
            productName: productName,
            productCategory: productCategory,
            targetCustomer: targetCustomer,
            competitor: competitor,
            positioning: positioning,
            targetGroup: targetGroup,
            productDetails: productDetails,
            holiday: holiday,
            offer: offer,
            framework: framework,
            language: language
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }

      if (response) {
        setError(null);
        setAiResponse(response?.data.data.aiResponse);
        setPromptMessage(response?.data.data.promptMessage);
        setLoading(false);
      }
    } catch (error: any) {
      if (error) {
        setError(error.response.data.errors);
        setLoading(false);
      }
    }
  }

  return (
    <form
      className="flex flex-col space-y-4 "
      onSubmit={(e) => submitHandler(e)}
    >
      <div className="grid lg:grid-cols-2 gap-4 sm:grid-cols-1 ">
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Sık Sorulan Sorular" ||
          selectedTitle === "FAQ" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title1")}
            </label>
            <input
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text1"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full "
            />
          </div>
        )}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Sık Sorulan Sorular" ||
          selectedTitle === "FAQ" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title2")}
            </label>
            <input
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text2"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        )}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Sık Sorulan Sorular" ||
          selectedTitle === "FAQ" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title3")}
            </label>
            <input
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text3"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        )}
        {/* {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title4")}
            </label>
            <input
              value={competitor}
              onChange={(e) => setCompetitor(e.target.value)}
              type="text"
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        )} */}
        {(selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title5")}
            </label>
            <input
              value={targetCustomer}
              onChange={(e) => setTargetCustomer(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text4"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        )}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title6")}
            </label>
            <input
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text5"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full  "
            />
          </div>
        )}
        {(selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title8")}
            </label>
            <input
              value={targetGroup}
              onChange={(e) => setTargetGroup(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text7"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        )}
        {(selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label className="text-white">
              {t("pages.home.inputField.typingInputs.title7")}
            </label>
            <input
              value={productDetails}
              onChange={(e) => setProductDetails(e.target.value)}
              type="text"
              placeholder={`${t(
                "pages.home.inputField.typingInputsPlaceholders.text6"
              )}`}
              className=" from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full  "
            />
          </div>
        )}
        {/* {typingTypes?.map(typingType=> <TypingText selectedTitle={selectedTitle} name={typingType.name}  question={typingType.question} inputValue={inputValue} setInputValue={setInputValue} setBrandName={setBrandName} setProductName={setProductName}/>)} */}
      </div>

      <div className="grid 2xl:grid-cols-5 gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 ">
        {/* {selectingTypes?.map(selectingType=> <div className='flex flex-col space-y-1'><label htmlFor="" className='text-white font-semibold '>{selectingType.question}</label> <select className=' px-2 py-1 text-white  from-gray-900 to-gray-600 bg-gradient-to-r rounded-lg focus:outline-none w-full'  name={selectingType.question} id={selectingType.name}>
           {selectingType.selections.map(option=>  <option className='hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800' value={option}>{option}</option>)}
          </select></div>)} */}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Sık Sorulan Sorular" ||
          selectedTitle === "FAQ" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Hakkımızda Sayfası İçeriği" ||
          selectedTitle === "About us Content" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title1")}
            </label>
            <select
              onChange={(e: any) => setPositioning(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"positioning"}
              id={"positioning"}
              value={positioning}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[0].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title2")}
            </label>
            <select
              onChange={(e: any) => setHoliday(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"holiday"}
              id={"holiday"}
              value={holiday}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[1].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {(selectedTitle === "Ana Sayfa Başlığı" ||
          selectedTitle === "Homepage Headline" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title3")}
            </label>
            <select
              onChange={(e: any) => setOffer(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"offer"}
              id={"offer"}
              value={offer}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[2].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {(selectedTitle === "Ana Sayfa İçeriği" ||
          selectedTitle === "Homepage Content" ||
          selectedTitle === "Makale" ||
          selectedTitle === "Article" ||
          selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description" ||
          selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption" ||
          selectedTitle === "Uygulama Bildirimi" ||
          selectedTitle === "App Notification" ||
          selectedTitle === "Tarayıcı Bildirimi" ||
          selectedTitle === "Browser Notification" ||
          selectedTitle === "Whatsapp Mesajı" ||
          selectedTitle === "Whatsapp Message" ||
          selectedTitle === "SMS" ||
          selectedTitle === "SMS Message" ||
          selectedTitle === "E-posta İçeriği" ||
          selectedTitle === "Email Content" ||
          selectedTitle === "E-posta Konusu" ||
          selectedTitle === "Email Subject Line" ||
          selectedTitle === "Sayfa Meta Açıklaması" ||
          selectedTitle === "Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Açıklaması" ||
          selectedTitle === "Product Page Meta Description" ||
          selectedTitle === "Ürün Sayfası Meta Başlığı" ||
          selectedTitle === "Product Page Meta Title" ||
          selectedTitle === "Sayfa Meta Başlığı" ||
          selectedTitle === "Page Meta Title" ||
          selectedTitle === "Ürün Açıklaması" ||
          selectedTitle === "Product Descriptions" ||
          selectedTitle === "Ürün Başlığı" ||
          selectedTitle === "Product Title" ||
          selectedTitle === "Koleksiyon Açıklaması" ||
          selectedTitle === "Collection Description" ||
          selectedTitle === "Koleksiyon Başlığı" ||
          selectedTitle === "Collection Title" ||
          selectedTitle === "Twitter") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title4")}
            </label>
            <select
              onChange={(e: any) => setFramework(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"framework"}
              id={"framework"}
              value={framework}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[3].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {(selectedTitle === "Sosyal Medya Sayfa Açıklaması" ||
          selectedTitle === "Social Media Page Description") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title5")}
            </label>
            <select
              onChange={(e: any) => setChannel(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"channel"}
              id={"channel"}
              value={channel}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[4].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {(selectedTitle === "Reklam Metni" ||
          selectedTitle === "Ad Copy" ||
          selectedTitle === "Reklam Başlığı" ||
          selectedTitle === "Ad Caption") && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title5")}
            </label>
            <select
              onChange={(e: any) => setChannel(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"channel"}
              id={"channel"}
              value={channel}
            >
              <option defaultValue="" value="">
                {t("pages.home.inputField.selectingInputs.selectText")}
              </option>
              {optionsNew[5].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedTitle && (
          <div className="flex flex-col space-y-1">
            <label htmlFor="" className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.title6")}
            </label>
            <select
              onChange={(e: any) => setLanguage(e.target.value)}
              className=" px-2 py-1 text-white bg-slate-800 rounded-lg focus:outline-none w-full"
              name={"language"}
              id={"language"}
              value={language}
            >
              {optionsNew[7].map((option) => (
                <option
                  key={option.en}
                  className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                  value={option.en}
                >
                  {i18n.resolvedLanguage === "en" ? option.en : option.tr}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {selectedTitle && (
        <div className="flex space-x-2  justify-center mt-2">
          <div className=" bg-gradient-to-r from-yellow-600 to-red-600 rounded-lg flex px-2 space-x-2 items-center space-x-2 justify-center ">
            <span className="text-white font-semibold ">
              {t("pages.home.inputField.selectingInputs.checkbox")}
            </span>
            <input
              type="checkbox"
              onChange={() => setAddToProduct(!addToProduct)}
            />
          </div>

          <button
            disabled={!selectedTitle}
            type="submit"
            className="text-white text-xl font-semibold bg-gradient-to-r from-yellow-600 to-red-600 px-4 py-1 rounded-lg cursor-pointer"
          >
            {t("pages.home.inputField.button")}
          </button>
        </div>
      )}
      <div>
        {loading && <Loading />}
        {!loading && (promptMessage || aiResponse) && (
          <div className="flex flex-col space-y-2 px-4 py-4">
            {/* <h1 className="font-medium sm:text-justify">
              <span className="font-bold">
                {t("pages.home.inputField.response.promptMessage")} :
              </span>
              {promptMessage}
            </h1> */}

            <h1 className="font-medium sm:text-justify">
              <span className="font-bold">
                {t("pages.home.inputField.response.cereResponse")} :
              </span>
              {aiResponse}
            </h1>
          </div>
        )}
        {error && (
          <div>
            {error &&
              error.map((err: any) => (
                <h1
                  key={err.message}
                  className="text-red-600 text-base font-medium "
                >
                  {err.message}
                </h1>
              ))}
          </div>
        )}
      </div>
    </form>
  );
}

export default FormInputs;
