import React, { useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import PromptInputs from "../components/PromptInputs";
import Footer from "../components/Footer";
import Powerdby from "../components/Powerdby";
import PromptIdeas from "../components/PromptIdeas";
import { useAppDispatch } from "../redux/store";
import { getAllProducts } from "../redux/features/productListingSlice";
import Cookies from "js-cookie";

function HomePage() {
  const prompter = useRef(null);
  const ideas = useRef(null);
  const dispatch = useAppDispatch();

  return (
    <div className=" min-h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black ">
      <Navbar prompter={prompter} ideas={ideas} />
      <HeroSection prompter={prompter} />
      <PromptInputs prompter={prompter} />
      <Powerdby ideas={ideas} />
      <PromptIdeas ideas={ideas} />
      <Footer ideas={ideas} prompter={prompter} />
    </div>
  );
}

export default HomePage;
