import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface userTokenLeftSliceState {
  tokenLeft: null | number;
}

const initialState: userTokenLeftSliceState = {
  tokenLeft: null,
};

export const fetchUserTokenLeft = createAsyncThunk(
  "user/userTokenLeft",
  async ({ token,BASE_URL }: any) => {
    const response = await axios.get(
      `${BASE_URL}/api/openAi/quotas`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data.userOpenAiLimit;
  }
);

export const userTokenLeftSlice = createSlice({
  name: "userTokenLeft",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTokenLeft.fulfilled, (state, { payload }) => {
      state.tokenLeft = payload;
    });
  },
});

export const {} = userTokenLeftSlice.actions;

export default userTokenLeftSlice.reducer;
