import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/beyazcerelogo.svg";
import { useTranslation } from "react-i18next";

function Footer({ prompter, ideas }: any) {
  const {t,i18n} = useTranslation()
  const lng = navigator.language
  const page = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (page.pathname.length > 1 && prompter) {
      navigate("/");
      prompter?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      prompter?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickIdeas = () => {
    if (page.pathname.length > 1 && prompter) {
      navigate("/");
      ideas?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      ideas?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="border-t border-slate-400 pb-2 mt-6 ">
      <div className="hidden sm:flex md:justify-between sm:justify-center sm:items-center w-full mx-auto flex-row flex-wrap md:w-4/6 border-b-2 border-slate-400 py-4">
        {/* left */}
        <div className="flex space-x-3 cursor-pointer">
          <img src={logo} alt="cere" className="w-10 h-10 md:w-14 md:h-14" />
          <div className="flex flex-col -space-y-1 md:-space-y-2">
            <h1 className="text-center text-white text-sm md:text-lg font-semibold">
              Cere E-Commerce
            </h1>
            <h1 className="text-center text-white text-sm md:text-lg font-semibold">
              Marketer
            </h1>
          </div>
        </div>
        {/* center */}
        <div className="hidden md:flex items-center space-x-4">
          <h1
            className="text-white cursor-pointer text-sm md:text-base "
            onClick={handleClick}
          >
                   {t("components.footer.text1")}

          </h1>
          <h1
            className="text-white cursor-pointer text-sm md:text-base "
            onClick={handleClickIdeas}
          >
            {t("components.footer.text2")}
          </h1>
        </div>
      </div>
      <div className="flex items-center justify-center py-4  ">
        <p className="text-white text-sm">
          <span className="text-lg font-medium">Cere</span> | © 2023 Cerebrum
          Technologies | All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
