import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductToInput,
  setSelectedProduct,
} from "../../redux/features/userProductsSlice";
import { RootState } from "../../redux/store";
import {
  deleteProduct,
  deleteProductFromArray,
} from "../../redux/features/productListingSlice";
import Cookies from "js-cookie";

interface UserProductInfoProps {
  brandName: string;
  productName: string;
  productCategory: string;
  id: string;
}

function UserProductInfo({
  brandName,
  productName,
  productCategory,
  id,
}: UserProductInfoProps) {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state: RootState) => state.userProduct.selectedProduct
  );
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = Cookies.get("access_token");

  // useEffect(() => {
  //   if (selectedProduct === id) {
  //     dispatch(setProductToInput({ brandName, productName, productCategory }));
  //   }
  // }, [selectedProduct, brandName, productName, productCategory, dispatch]);

  function handleDelete() {
    dispatch(deleteProductFromArray(id));
    deleteProduct({ BASE_URL, token, id });
  }

  return (
    <div
      className={`border w-full relative border-slate-400 cursor-pointer rounded-lg mt-2 ${
        selectedProduct === id &&
        "bg-gradient-to-r from-red-800 via-yellow-600 to-yellow-500"
      }`}
      onClick={() => {
        dispatch(setSelectedProduct(id));
        dispatch(
          setProductToInput({ brandName, productName, productCategory })
        );
      }}
    >
      <h3
        className="text-white absolute right-1 top-0 text-xs font-bold"
        onClick={handleDelete}
      >
        X
      </h3>

      <div className="flex flex-col -space-y-1 items-center ">
        <h2 className="text-white xl:text-sm md:text-xs font-medium text-center">
          {brandName}
        </h2>
        <h1 className="text-white xl:text-xl md:text-lg font-semibold text-center">
          {productName}
        </h1>
        <h2 className="text-white xl:text-sm md:text-xs font-medium text-center">
          {productCategory}
        </h2>
      </div>
    </div>
  );
}

export default UserProductInfo;
