import React, { useState } from "react";
import logo from "../assets/beyazcerelogo.svg";
import { useSignOut } from "react-auth-kit";
import UserInfo from "./Navbar/UserInfo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Language from "./Navbar/Language";
import { MdLanguage } from "react-icons/md";
import { resetArray } from "../redux/features/productListingSlice";
function Navbar({ prompter, ideas }: any) {
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const page = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOutFromApp = useSignOut();
  const signOut = () => {
    //localStorage.removeItem("persist:user");
    dispatch(resetArray());
    signOutFromApp();
  };
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);

  const userName = useSelector((state: RootState) => state.user.user);
  const companyName = useSelector((state: RootState) => state.user.company);

  const handleClick = () => {
    if (page.pathname.length > 1 && prompter) {
      navigate("/");
      prompter?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      prompter?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickIdeas = () => {
    if (page.pathname.length > 1 && prompter) {
      navigate("/");
      ideas?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      ideas?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex justify-between w-full mx-auto flex-row flex-wrap md:w-4/6">
      {/* left */}
      <div
        className="flex space-x-3 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="cere" className="w-10 h-10 md:w-14 md:h-14" />
        <div className="flex flex-col -space-y-1 md:-space-y-2">
          <h1 className="text-center text-white text-sm md:text-lg font-semibold">
            Cere E-Commerce
          </h1>
          <h1 className="text-center text-white text-sm md:text-lg font-semibold">
            Marketer
          </h1>
        </div>
      </div>
      {/* center */}
      <div className="hidden md:flex items-center space-x-4">
        <h1
          className="text-white cursor-pointer text-sm md:text-base font-medium"
          onClick={handleClick}
        >
          {t("components.navbar.main.text1")}
        </h1>
        <h1
          className="text-white cursor-pointer text-sm md:text-base font-medium"
          onClick={handleClickIdeas}
        >
          {t("components.navbar.main.text2")}
        </h1>
      </div>
      {/* right */}
      <div className="hidden md:flex space-x-2 items-center">
        <div>
          <div
            className="hidden md:flex cursor-pointer"
            onMouseOver={() => setShowLanguages(true)}
            onMouseOut={() => setShowLanguages(false)}
          >
            <MdLanguage size={28} color="#ec9114" className="cursor-pointer" />
          </div>
          {showLanguages && (
            <div
              className="relative"
              onMouseOver={() => setShowLanguages(true)}
            >
              <div
                className="absolute right-2  w-[500px]"
                onMouseOver={() => setShowLanguages(true)}
                onMouseOut={() => setShowLanguages(false)}
              >
                <div className="pt-4 absolute z-10 -right-14">
                  <Language />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            className="hidden md:flex items-center rounded-lg bg-gradient-to-r from-violet-300 to-violet-400 cursor-pointer"
            onMouseOver={() => setShowUserInfo(true)}
            onMouseOut={() => setShowUserInfo(false)}
          >
            <h1 className="text-white font-semibold text-sm md:text-lg px-2 py-1 text-center">
              {companyName ? companyName : userName}
            </h1>
          </div>
          {showUserInfo && (
            <div className="relative" onMouseOver={() => setShowUserInfo(true)}>
              <div
                className="absolute right-2  w-[500px]"
                onMouseOver={() => setShowUserInfo(true)}
                onMouseOut={() => setShowUserInfo(false)}
              >
                <div className="pt-4 absolute z-10 -right-14">
                  <UserInfo signOut={signOut} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex md:hidden space-x-2 items-center">
        <div>
          <div
            className=" cursor-pointer"
            onClick={() => {
              setShowLanguages(!showLanguages);

              setShowUserInfo(false);
            }}
          >
            <MdLanguage size={28} color="#ec9114" className="cursor-pointer" />
          </div>
          {showLanguages && (
            <div
              className="relative"
              onClick={() => setShowLanguages(!showLanguages)}
            >
              <div
                className="absolute md:hidden right-2  w-[500px]"
                onClick={() => setShowLanguages(!showLanguages)}
              >
                <div className="pt-4 absolute z-10 -right-14">
                  <Language />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            className="flex md:hidden items-center rounded-lg bg-gradient-to-r from-violet-300 to-violet-400 cursor-pointer"
            onClick={() => {
              setShowUserInfo(!showUserInfo);
              setShowLanguages(false);
            }}
          >
            <h1 className="text-white font-semibold text-sm md:text-lg px-2 py-1 text-center">
              {companyName ? companyName : userName}
            </h1>
          </div>
          {showUserInfo && (
            <div
              className="relative"
              onClick={() => setShowUserInfo(!showUserInfo)}
            >
              <div
                className="absolute w-[300px] md:hidden right-6 md:w-[500px]"
                onClick={() => setShowUserInfo(!showUserInfo)}
              >
                <div className="pt-4 absolute z-10 md:-right-14 -right-2 -top-2 md:top-0 w-[200px]">
                  <UserInfo signOut={signOut} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
