import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { fetchUserTokenLeft } from "../../redux/features/userTokenLeftSlice";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import UserProductInfo from "../PromptInputsComponent/UserProductInfo";
import { getAllProducts } from "../../redux/features/productListingSlice";

function UserInformation({ userInformation }: any) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = Cookies.get("access_token");
  const { t, i18n } = useTranslation();
  const usersProducts = useSelector(
    (state: RootState) => state.productListings.allProducts
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchUserTokenLeft({ token, BASE_URL }));
    dispatch(getAllProducts({ token, BASE_URL }));
  }, []);
  const userTokenLeft = useSelector(
    (state: RootState) => state.userTokenLeft.tokenLeft
  );
  return (
    <div className="flex mx-auto w-5/6 sm:w-4/6 border border-slate-400 rounded-lg mt-5 sm:p-3 bg-gradient-to-t from-gray-200 via-gray-400 to-gray-600 items-center">
      <div className="flex flex-col w-full h-full p-3 rounded-lg  items-center space-y-2 w-1/2">
        {userInformation?.name && (
          <h1 className="font-bold text-white text-xl">
            {t("pages.profile.text1")} : {userInformation?.name}
          </h1>
        )}
        {userInformation?.membership && (
          <h1 className="font-bold text-white text-xl">
            {t("pages.profile.text2")} : {userInformation?.membership}
          </h1>
        )}

        {userTokenLeft !== null && (
          <h1 className="font-bold text-white text-xl">
            {t("pages.profile.text3")} : {userTokenLeft}
          </h1>
        )}
        <div
          className="bg-gradient-to-r from-yellow-600 to-red-600 rounded-lg hidden lg:flex items-center justify-center cursor-pointer"
          onClick={() =>
            window.location.replace("https://ceremeet.com/#pricing")
          }
        >
          <h1 className="text-white font-semibold px-4 py-1  ">
            {t("pages.profile.button")}
          </h1>
        </div>
      </div>
      <div className="w-full">
        <h1 className="text-white text-xl font-semibold border-slate-400 border-b-2 pb-2">
          {t("pages.home.userProducts.mainTitle")}
        </h1>{" "}
        <div className="grid lg:grid-cols-6 gap-2 md:grid-cols-4 sm:grid-cols-2 ">
          {usersProducts.map((product: any) => (
            <UserProductInfo
              key={product.id}
              id={product.id}
              brandName={product.brandName}
              productName={product.name}
              productCategory={product.category}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserInformation;
