import React from "react";
import cere from "../assets/cere.png";
import { useTranslation } from "react-i18next";
function HeroSection({ prompter }: any) {
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const handleClick = () => {
    prompter.current.scrollIntoView({ behavior: "smooth" });
  };

  const dataEN = [
    {
      id: 1,
      number: "1",
      text1: "Select your",
      text2: "content type",
    },
    {
      id: 2,
      number: "2",
      text1: "Customize",
      text2: "your prompt",
    },
    {
      id: 3,
      number: "3",
      text1: "Hit generate to",
      text2: "get your output",
    },
  ];
  const dataTR = [
    {
      id: 1,
      number: "1",
      text1: "İçerik türünüzü",
      text2: "seçin",
    },
    {
      id: 2,
      number: "2",
      text1: "Promptunuzu",
      text2: "özelleştirin",
    },
    {
      id: 3,
      number: "3",
      text1: "Fikir Üret'e",
      text2: "basın",
    },
  ];

  return (
    <div className="flex mx-auto justify-center w-1/2 items-center mt-2 ">
      <div className="w-full flex space-x-4 items-center ">
        <img src={cere} alt="cere" className="w-1/3" />
        <div className="flex flex-col items-center w-2/3 -space-y-2">
          <div className="flex flex-col sm:space-y-4  ">
            <h1 className="text-sm sm:text-lg md:text-xl lg:text-4xl font-bold text-white text-center">
              {t("pages.home.hero.header.title1")}
            </h1>
            <h3 className="text-lg text-slate-400 font-medium text-center hidden sm:block">
              {t("pages.home.hero.description")}
            </h3>
          </div>
          <div className=" hidden lg:flex space-x-2 ">
            {(i18n.resolvedLanguage === "en" ? dataEN : dataTR).map((data) => (
              <div className="flex items-center -space-x-1" key={data.id}>
                <h1 className=" lg:text-[80px] xl:text-[100px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-red-600">
                  {data.number}
                </h1>
                <div className="flex flex-col text-center">
                  <h3 className="text-white  lg:text-base xl:text-lg  2xl:text-xl font-medium">
                    {data.text1}
                  </h3>
                  <h3 className="text-white   lg:text-base xl:text-lg 2xl:text-xl font-medium">
                    {data.text2}
                  </h3>
                </div>
              </div>
            ))}
          </div>
          <div
            className="cursor-pointer  items-center border border-gradient-to-r from-pink-500 via-red-500 to-yellow-500  rounded-lg hidden lg:flex hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
            onClick={handleClick}
          >
            <p className="text-white text-xl font-semibold px-2 py-1">
              {t("pages.home.hero.button")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
