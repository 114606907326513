import React, { useEffect, useRef } from "react";
import Information from "./PromptInputsComponent/Information";
import ContentTypes from "./PromptInputsComponent/ContentTypes";
import { useDispatch } from "react-redux";
import { getAllProducts } from "../redux/features/productListingSlice";
import Cookies from "js-cookie";
import { useAppDispatch } from "../redux/store";

function PromptInputs({ prompter }: any) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = Cookies.get("access_token");
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(getAllProducts({ token, BASE_URL }));
  //   }, 2000);
  // }, []);
  return (
    <div
      className="flex mx-auto w-5/6 sm:w-4/6 border border-slate-400 rounded-lg mt-5 sm:p-3"
      ref={prompter}
    >
      <div className="flex flex-col w-full h-full p-3 rounded-lg bg-gradient-to-t from-gray-200 via-gray-400 to-gray-600">
        <Information />
        <ContentTypes />
      </div>
    </div>
  );
}

export default PromptInputs;
