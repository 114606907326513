import React, { useState } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { getAllProducts } from "../../redux/features/productListingSlice";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

interface ContentTypeTitleProps {
  title: string;
  setSelectedTitle: (title: any) => void;
  selectedTitle: any;
}

function ContentTypeTitle({
  title,
  setSelectedTitle,
  selectedTitle
}: ContentTypeTitleProps) {
  const dispatch = useAppDispatch();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = useSelector((state: RootState) => state.user.token);
  return (
    <div
      className={`flex items-center border border-slate-400 cursor-pointer rounded-lg mt-2 ${
        selectedTitle === title &&
        "bg-gradient-to-r from-red-800 via-yellow-600 to-yellow-500"
      }`}
      onClick={() => {
        setSelectedTitle(title);
        dispatch(getAllProducts({ token, BASE_URL }));
      }}
    >
      <h1 className="text-white text-base font-medium text-center px-2 py-1">
        {title}
      </h1>
    </div>
  );
}

export default ContentTypeTitle;
