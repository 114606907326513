import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import persistedReducer from "./features/userSlice";
import persistStore from "redux-persist/es/persistStore";
import userProductsReducer from "./features/userProductsSlice";
import userTokenLeftReducer from "./features/userTokenLeftSlice";
import productListingReducer from "./features/productListingSlice";
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

  reducer: {
    user: persistedReducer,
    userProduct: userProductsReducer,
    userTokenLeft: userTokenLeftReducer,
    productListings: productListingReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
