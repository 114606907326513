export const contentTypes = [
  {
    nameEN: "Homepage Headline",
    nameTR: "Ana Sayfa Başlığı",
    id: 1,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      },
      {
        name: "productDetails",
        isRequired: false,
        question: "Tell us more about your product(optional)",
        type: "typing",
        placeholder: "More Details",
        limit: 300
      },
      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "Homepage Content",
    nameTR: "Ana Sayfa İçeriği",
    id: 2,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "About us Content",
    nameTR: "Hakkımızda Sayfası İçeriği",
    id: 3,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "Collection Title",
    nameTR: "Koleksiyon Başlığı",
    id: 4,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "Collection Description",
    nameTR: "Koleksiyon Açıklaması",
    id: 5,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "FAQ",
    nameTR: "Sık Sorulan Sorular",
    id: 6,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      }
    ]
  },
  {
    nameEN: "Product Title",
    nameTR: "Ürün Başlığı",
    id: 7,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Product Descriptions",
    nameTR: "Ürün Açıklaması",
    id: 8,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },
      {
        name: "targetGroup",
        isRequired: false,
        question: "Target groups?(optional)",
        type: "typing",
        placeholder: "Person who likes furnitures...",
        limit: 200
      },

      {
        name: "productDetails",
        isRequired: false,
        question: "Tell us more about your product(optional)",
        type: "typing",
        placeholder: "More Details",
        limit: 300
      },
      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "Page Meta Title",
    nameTR: "Sayfa Meta Başlığı",
    id: 9,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Page Meta Description",
    nameTR: "Sayfa Meta Açıklaması",
    id: 10,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Product Page Meta Title",
    nameTR: "Ürün Sayfası Meta Başlığı",
    id: 11,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Product Page Meta Description",
    nameTR: "Ürün Sayfası Meta Açıklaması",
    id: 12,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Email Subject Line",
    nameTR: "E-posta Konusu",
    id: 13,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      }
    ]
  },
  {
    nameEN: "Email Content",
    nameTR: "E-posta İçeriği",
    id: 14,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "SMS Message",
    nameTR: "SMS",
    id: 15,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Whatsapp Message",
    nameTR: "Whatsapp Mesajı",
    id: 16,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Browser Notification",
    nameTR: "Tarayıcı Bildirimi",
    id: 17,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "App Notification",
    nameTR: "Uygulama Bildirimi",
    id: 18,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Ad Caption",
    nameTR: "Reklam Başlığı",
    id: 19,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Ad Copy",
    nameTR: "Reklam Metni",
    id: 20,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Social Media Page Description",
    nameTR: "Sosyal Medya Sayfa Açıklaması",
    id: 21,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  },
  {
    nameEN: "Article",
    nameTR: "Makale",
    id: 22,
    typingRequirements: [
      {
        name: "brandName",
        isRequired: true,
        question: "What your Brand Name?*",
        type: "typing",
        placeholder: "Inaction",
        limit: 50
      },
      {
        name: "productName",
        isRequired: true,
        question: "What Product are you selling?*",
        type: "typing",
        placeholder: "Desks for office",
        limit: 50
      },
      {
        name: "productCategory",
        isRequired: true,
        question: "Product Category?*",
        type: "typing",
        placeholder: "Wood, office, desks, ..."
      },

      {
        name: "competitor",
        isRequired: true,
        question: "What are your Competitors?*",
        type: "typing",
        placeholder: "Ikea",
        limit: 50
      },
      {
        name: "targetCustomer",
        isRequired: true,
        question: "Target customers?*",
        type: "typing",
        placeholder: "students,women,soliders  ...",
        limit: 200
      },
      {
        name: "industry",
        isRequired: true,
        question: "What Industry your product in?*",
        type: "typing",
        placeholder: "Wood",
        limit: 200
      }
    ]
  }
  // {
  //   nameEN: "Twitter",
  //   nameTR: "Twitter",
  //   id: 21,
  //   typingRequirements: [
  //     {
  //       name: "brandName",
  //       isRequired: true,
  //       question: "What your Brand Name?*",
  //       type: "typing",
  //       placeholder: "Inaction",
  //       limit: 50,
  //     },
  //     {
  //       name: "productName",
  //       isRequired: true,
  //       question: "What Product are you selling?*",
  //       type: "typing",
  //       placeholder: "Desks for office",
  //       limit: 50,
  //     },
  //     {
  //       name: "productCategory",
  //       isRequired: true,
  //       question: "Product Category?*",
  //       type: "typing",
  //       placeholder: "Wood, office, desks, ...",
  //     },

  //     {
  //       name: "competitor",
  //       isRequired: true,
  //       question: "What are your Competitors?*",
  //       type: "typing",
  //       placeholder: "Ikea",
  //       limit: 50,
  //     },
  //     {
  //       name: "targetCustomer",
  //       isRequired: true,
  //       question: "Target customers?*",
  //       type: "typing",
  //       placeholder: "students,women,soliders  ...",
  //       limit: 200,
  //     },
  //     {
  //       name: "industry",
  //       isRequired: true,
  //       question: "What Industry your product in?*",
  //       type: "typing",
  //       placeholder: "Wood",
  //       limit: 200,
  //     },
  //   ],
  //   selectingRequirements: [
  //     {
  //       name: "positioning",
  //       isRequired: false,
  //       question: "Positioning(optional)",
  //       type: "selecting",
  //       selectionsEN: [
  //         "comfort",
  //         "sustainable",
  //         "price-sensitive",
  //         "quality",
  //         "exclusivity",
  //         "lifestyle",
  //         "confidence",
  //         "innovation",
  //         "story-telling",
  //         "trend-setting",
  //         "emotional",
  //         "aspirational",
  //         "nostalgia",
  //         "experiental",
  //         "heritage",
  //         "functionality",
  //         "simplicity",
  //         "inclusivity",
  //       ],
  //       selectionsTR: [
  //         "konfor",
  //         "sürdürülebilir",
  //         "fiyata duyarlı",
  //         "kalite",
  //         "münhasırlık",
  //         "yaşam tarzı",
  //         "kendinden emin",
  //         "yenilik",
  //         "hikaye anlatımı",
  //         "trend belirleyici",
  //         "duygusal",
  //         "aspirational",
  //         "nostalji",
  //         "deneyimsel",
  //         "miras",
  //         "işlevsellik",
  //         "basitlik",
  //         "kapsayıcılık",
  //       ],
  //     },
  //     {
  //       name: "holiday",
  //       isRequired: false,
  //       question: "Holiday(optional)",
  //       type: "selecting",
  //       selectionsEN: [
  //         "new year",
  //         "halloween",
  //         "valentines",
  //         "mothers-day",
  //         "fathers-day",
  //         "feast of ramadan",
  //         "feast of sacrifice",
  //         "hygiene day",
  //         "cyber-monday",
  //         "black-friday",
  //         "national sovereignty and children's day",
  //         "commemoration of Atatürk, youth and sports day",
  //         "victory day",
  //         "republic day",
  //       ],
  //       selectionsTR: [
  //         "yılbaşı",
  //         "cadılar bayramı",
  //         "sevgililer günü",
  //         "anneler günü",
  //         "babalar günü",
  //         "ramazan bayramı",
  //         "kurban bayramı",
  //         "hijyen günü",
  //         "siber pazartesi",
  //         "black friday",
  //         "ulusal egemenlik ve çocuk bayramı",
  //         "Atatürk'ü anma, gençlik ve spor bayramı",
  //         "zafer bayramı",
  //         "cumhuriyet bayramı",
  //       ],
  //     },
  //     {
  //       name: "offer",
  //       isRequired: false,
  //       question: "Offer(optional)",
  //       type: "selecting",
  //       selectionsEN: [
  //         "free-gift-with-purchase",
  //         "bundle-offer",
  //         "limited-time-offer",
  //         "referal-bonus",
  //         "free-shipping",
  //         "buy-one-get-one",
  //         "free-trial",
  //         "abandonment-discount",
  //         "pre-order-discount",
  //         "gift-card",
  //         "loyalty-program",
  //         "seasonal-discount",
  //         "limited-edition",
  //         "flash-sale",
  //       ],
  //       selectionsTR: [
  //         "satın alma ile ücretsiz hediye",
  //         "paket-teklif",
  //         "sınırlı zaman teklif",
  //         "tavsiye bonusu",
  //         "ücretsiz kargo",
  //         "bir alana bir alana",
  //         "ücretsiz deneme",
  //         "terk-indirim",
  //         "ön sipariş indirimi",
  //         "hediye kartı",
  //         "sadakat kartı",
  //         "sezonluk indirim",
  //         "sınırlı sayıda",
  //         "hızlı satış",
  //       ],
  //     },
  //     {
  //       name: "framework",
  //       isRequired: false,
  //       question: "Framework(optional)",
  //       type: "selecting",
  //       selections: [
  //         "aida",
  //         "urgency-patience",
  //         "emotion-logic",
  //         "personal-universal",
  //         "consistent-contrasting",
  //         "story-solve-sell",
  //         "solution-savings-social-proof",
  //         "expectation-surprise",
  //         "exclusive-inclusive",
  //         "fomo",
  //         "friend-expert",
  //         "niche-differentiation",
  //         "social-proof",
  //         "past-present-future",
  //         "before-after",
  //         "unique-value-proposition",
  //         "problem-solution",
  //         "pain-agitate-relief",
  //       ],
  //     },
  //   ],
  // },
];
export const promptIdeas = [
  {
    id: 1,
    title: "Homepage headline",
    idea: "Behave like an ecommerce expert and create a homepage headline for a [Industry] product called '[product]' from the brand '[brand]'"
  },
  {
    id: 2,
    title: "Homepage Content",
    idea: "Act like an ecommerce content writer and create homepage content for a [industry] product by the brand '[brand]'"
  },
  {
    id: 3,
    title: "About Us content",
    idea: "Think like a PR professional creating content for a website. Write content for the about us section of a [Industry] brand called '[brand]'."
  },
  {
    id: 4,
    title: "Collection Title",
    idea: "Act as an ecommerce merchandising expert and create a product collection title for [product] from [brand]."
  },
  {
    id: 5,
    title: "Collection Description",
    idea: "Act as an ecommerce merchandising expert and create a product collection description for the [product] from the brand [brand]"
  },
  {
    id: 6,
    title: "FAQ",
    idea: "Behave like an ecommerce retail product specialist and create a list of FAQs with answers for the product '[product]' by the brand [brand]."
  },
  {
    id: 7,
    title: "Product titles",
    idea: "Behave like an ecommerce merchandising specialist and draft a product title for '[product]' from '[brand]', a [industry] brand."
  },
  {
    id: 8,
    title: "Product Descriptions",
    idea: "Think like an ecommerce merchandising specialist and write a product description to list [product] on an ecommerce store {brand] for a customer who is a [ICP]"
  },
  {
    id: 9,
    title: "Page Meta Title",
    idea: "Think like an ecommerce SEO expert and generate a page meta title for the brand '[brand]' from the [industry] industry"
  },
  {
    id: 10,
    title: "Page Meta Description",
    idea: "Think like an ecommerce SEO expert and generate a page meta description for the brand '[brand]' from the [industry] industry"
  },
  {
    id: 11,
    title: "Email Subject Line",
    idea: "Act like an email marketing specialist and draft an email subject line for [product] by the brand [brand] using a maximum of 50 characters."
  },
  {
    id: 12,
    title: "Email Content",
    idea: "Act like an email marketing expert for ecommerce and draft an email campaign for the product '[product]' from the brand '[brand]', belonging to the [industry] industry. The campaign is intended for a [ICP]."
  },
  {
    id: 13,
    title: "SMS message",
    idea: "Think like an ecommerce marketing expert and create an SMS campaign for the product '[product]' from the brand '[brand]'"
  },
  {
    id: 14,
    title: "Whatsapp Message",
    idea: "Behave like an ecommerce marketing expert for Whatsapp and create a campaign for the product '[product]' from the brand '[brand]'"
  },
  {
    id: 15,
    title: "Browser Notification",
    idea: "Think like an ecommerce website expert and generate a browser notification for the product '[product]' for the store '[brand]'"
  },
  {
    id: 16,
    title: "App Notification",
    idea: "Think like an ecommerce product expert and generate an app notification for the product '[product]' for the app '[brand]'"
  },
  {
    id: 17,
    title: "Ad caption",
    idea: "Think like an ecommerce digital advertiser and create an ad caption for the product [product] from the brand '[brand]. Optimize the caption for [channel] ads and relevant character limits."
  },
  {
    id: 18,
    title: "Ad copy",
    idea: "Think like an ecommerce digital advertising copywriter and create ad copy for the product [product] from the brand '[brand]'. Optimize the copy for [channel] ads."
  },
  {
    id: 19,
    title: "Social media page description",
    idea: "Think like an ecommerce social media specialist and write a 90 character [channel] page description for a [industry] brand named [brand] which sells [product]"
  },
  {
    id: 20,
    title: "Article",
    idea: "Act as an ecommerce content writer and write an article on the product '[product]' from the brand '[brand]'"
  },
  {
    id: 21,
    title: "Product Page Meta Description",
    idea: "Think like an ecommerce SEO expert and generate a page meta description for [product] from brand '[brand]' from the [industry] industry"
  },
  {
    id: 22,
    title: "Product Page Meta Title",
    idea: "Think like an ecommerce SEO expert and generate a page meta title for [product] from the brand '[brand]' from the [industry] industry"
  }
];

export const promptIdeasTurkish = [
  {
    id: 1,
    title: "Ana sayfa başlığı",
    idea: "Bir e-ticaret uzmanı gibi davranın ve '[marka]' markasından '[ürün]' adlı bir [Sektör] ürünü için bir ana sayfa başlığı oluşturun"
  },
  {
    id: 2,
    title: "Ana Sayfa İçeriği",
    idea: "Bir e-ticaret içerik yazarı gibi davranın ve '[marka]' markalı bir [endüstri] ürünü için ana sayfa içeriği oluşturun"
  },
  {
    id: 3,
    title: "Hakkımızda içeriği",
    idea: "Bir web sitesi için içerik oluşturan bir halkla ilişkiler uzmanı gibi düşünün. '[marka]' adlı bir [Endüstri] markasının hakkımızda bölümü için içerik yazın."
  },
  {
    id: 4,
    title: "Koleksiyon Açıklaması",
    idea: "Bir e-ticaret ticaret uzmanı olarak hareket edin ve [marka] markasından [ürün] için bir ürün koleksiyonu açıklaması oluşturun"
  },
  {
    id: 5,
    title: "Ürün başlıkları",
    idea: "Bir e-ticaret satış uzmanı gibi davranın ve bir [endüstri] markası olan '[marka]'dan '[ürün]' için bir ürün başlığı hazırlayın."
  },
  {
    id: 6,
    title: "Koleksiyon Başlığı",
    idea: "Bir e-ticaret satış uzmanı olarak hareket edin ve [marka]'dan [ürün] için bir ürün koleksiyonu başlığı oluşturun."
  },
  {
    id: 7,
    title: "Sayfa Meta Başlığı",
    idea: "Bir e-ticaret SEO uzmanı gibi düşünün ve [endüstri] endüstrisinden '[marka]' markası için bir sayfa meta başlığı oluşturun"
  },
  {
    id: 8,
    title: "Ürün Sayfası Meta Açıklaması",
    idea: "Bir e-ticaret SEO uzmanı gibi düşünün ve [endüstri] endüstrisinden '[marka]' markasından [ürün] için bir sayfa meta açıklaması oluşturun"
  },
  {
    id: 9,
    title: "Sayfa Meta Açıklaması",
    idea: "Bir e-ticaret SEO uzmanı gibi düşünün ve [endüstri] endüstrisinden '[marka]' markası için bir sayfa meta açıklaması oluşturun"
  },
  {
    id: 10,
    title: "Ürün Açıklaması",
    idea: "Bir e-ticaret ticaret uzmanı gibi düşünün ve [ICP] olan bir müşteri için bir e-ticaret mağazasında {marka] [ürün] listelemek için bir ürün açıklaması yazın."
  },
  {
    id: 11,
    title: "E-posta İçeriği",
    idea: "E-ticaret için bir e-posta pazarlama uzmanı gibi davranın ve [endüstri] endüstrisine ait '[marka]' markasının '[ürün]' ürünü için bir e-posta kampanyası hazırlayın. Kampanya bir [ICP] için tasarlanmıştır."
  },
  {
    id: 12,
    title: "Kısa Mesaj(SMS)",
    idea: "Bir e-ticaret pazarlama uzmanı gibi düşünün ve '[brand]' markasının '[product]' ürünü için bir SMS kampanyası oluşturun"
  },
  {
    id: 13,
    title: "Whatsapp Mesajı",
    idea: "Whatsapp için bir e-ticaret pazarlama uzmanı gibi davranın ve '[marka]' markasından '[ürün]' ürünü için bir kampanya oluşturun"
  },
  {
    id: 14,
    title: "Tarayıcı Bildirimi",
    idea: "Bir e-ticaret web sitesi uzmanı gibi düşünün ve '[marka]' mağazası için '[ürün]' ürünü için bir tarayıcı bildirimi oluşturun"
  },
  {
    id: 15,
    title: "Uygulama bildirimi",
    idea: "Bir e-ticaret ürün uzmanı gibi düşünün ve '[marka]' uygulaması için '[ürün]' ürünü için bir uygulama bildirimi oluşturun"
  },
  {
    id: 16,
    title: "Reklam başlığı",
    idea: "Bir e-ticaret dijital reklamvereni gibi düşünün ve '[marka] markasından [ürün] ürünü için bir reklam başlığı oluşturun. Altyazıyı [kanal] reklamları ve alakalı karakter sınırlamaları için optimize edin."
  },
  {
    id: 17,
    title: "Reklam Metni",
    idea: "Bir e-ticaret dijital reklam metin yazarı gibi düşünün ve '[marka]' markasından [ürün] ürünü için reklam metni oluşturun. [Kanal] reklamları için kopyayı optimize edin."
  },
  {
    id: 18,
    title: "Makale",
    idea: "Bir e-ticaret içerik yazarı olarak hareket edin ve '[marka]' markasından '[ürün]' ürünü hakkında bir makale yazın"
  },
  {
    id: 19,
    title: "Sosyal Medya Sayfası Açıklaması",
    idea: "Bir e-ticaret sosyal medya uzmanı gibi düşünün ve [ürün] satan [marka] adlı bir [endüstri] markası için 90 karakterlik bir [kanal] sayfası açıklaması yazın."
  },
  {
    id: 20,
    title: "Sık Sorulan Sorular",
    idea: "Bir e-ticaret perakende ürün uzmanı gibi davranın ve '[ürün]' ürünü için [marka] tarafından verilen yanıtları içeren bir SSS listesi oluşturun."
  },
  {
    id: 21,
    title: "Ürün Sayfası Meta Açıklaması",
    idea: "Bir e-ticaret SEO uzmanı gibi düşünün ve [endüstri] endüstrisinden '[marka]' markasından [ürün] için bir sayfa meta açıklaması oluşturun"
  },
  {
    id: 22,
    title: "Ürün Sayfası Meta Başlığı",
    idea: "Bir e-ticaret SEO uzmanı gibi düşünün ve [endüstri] endüstrisinden '[marka]' markasından [ürün] için bir sayfa meta başlığı oluşturun"
  }
];

export const optionsNew = [
  [
    { tr: "rahat", en: "comfort" },
    { tr: "sürdürülebilir", en: "sustainable" },
    { tr: "fiyata duyarlı", en: "price-sensitive" },
    { tr: "kalite", en: "quality" },
    { tr: "eşsizlik,özgünlük", en: "exclusivity" },
    { tr: "yaşam tarzı", en: "lifestyle" },
    { tr: "güven", en: "confidence" },
    { tr: "yenilik", en: "innovation" },
    { tr: "hikaye anlatımı", en: "story-telling" },
    { tr: "trend belirleyici", en: "trend-setting" },
    { tr: "duygusal", en: "emotional" },
    { tr: "arzu edilen", en: "aspirational" },
    { tr: "nostalji", en: "nostalgia" },
    { tr: "deneyimsel", en: "experiental" },
    { tr: "miras", en: "heritage" },
    { tr: "fonksiyonellik", en: "functionality" },
    { tr: "basitlik", en: "simplicity" },
    { tr: "kapsayıcılık", en: "inclusivity" }
  ],
  [
    { tr: "yılbaşı", en: "new year" },
    { tr: "cadılar bayramı", en: "halloween" },
    { tr: "sevgililer günü", en: "valentines" },
    { tr: "anneler günü", en: "mothers-day" },
    { tr: "babalar günü", en: "fathers-day" },
    { tr: "ramazan bayramı", en: "feast of ramadan" },
    { tr: "kurban bayramı", en: "feast of sacrifice" },
    { tr: "hijyen günü", en: "hygiene day" },
    { tr: "siber pazartesi", en: "cyber-monday" },
    { tr: "black friday", en: "black-friday" },
    {
      tr: "ulusal egemenlik ve çocuk bayramı",
      en: "national sovereignty and children's day"
    },
    {
      tr: "Atatürk'ü anma, gençlik ve spor bayramı",
      en: "commemoration of Atatürk, youth and sports day"
    },
    { tr: "zafer bayramı", en: "victory day" },
    { tr: "cumhuriyet bayramı", en: "republic day" }
  ],
  [
    { tr: "satın alma ile birlikte hediye", en: "free-gift-with-purchase" },
    { tr: "paket teklif", en: "bundle-offer" },
    { tr: "sınırlı süreli teklif", en: "limited-time-offer" },
    { tr: "yönlendirme bonusu", en: "referal-bonus" },
    { tr: "ücretsiz kargo", en: "free-shipping" },
    { tr: "bir alana bir alana", en: "buy-one-get-one" },
    { tr: "ücretsiz deneme", en: "free-trial" },
    { tr: "terk etme indirimi", en: "abandonment-discount" },
    { tr: "ön sipariş indirimi", en: "pre-order-discount" },
    { tr: "hediye kartı", en: "gift-card" },
    { tr: "sadakat programı", en: "loyalty-program" },
    { tr: "sezon indirimi", en: "seasonal-discount" },
    { tr: "sınırlı sayıda üretilen/özel seri", en: "limited-edition" },
    { tr: "hızlı satış/fırsat satışı", en: "flash-sale" }
  ],
  [
    {
      tr: "ilgilendirme-ilgilenme-isteklendirme-aksiyon alma(aida)",
      en: "aida"
    },
    { tr: "aciliyet-sabır", en: "urgency-patience" },
    { tr: "duygu-mantık", en: "emotion-logic" },
    { tr: "kişisel-evrensel", en: "personal-universal" },
    { tr: "tutarlı-zıt", en: "consistent-contrasting" },
    { tr: "hikaye-çözüm-satış", en: "story-solve-sell" },
    { tr: "çözüm-tasarruf-sosyal kanıt", en: "solution-savings-social-proof" },
    { tr: "beklenti-sürpriz", en: "expectation-surprise" },
    { tr: "özel-kapsayıcı", en: "exclusive-inclusive" },
    { tr: "fırsat kaçırma endişesi(fomo)", en: "fomo" },
    { tr: "arkadaş-uzman", en: "friend-expert" },
    { tr: "niş-farklılaştırma", en: "niche-differentiation" },
    { tr: "sosyal kanıt", en: "social-proof" },
    { tr: "geçmiş-şimdi-gelecek", en: "past-present-future" },
    { tr: "öncesi-sonrası", en: "before-after" },
    { tr: "benzersiz değer teklifi", en: "unique-value-proposition" },
    { tr: "sorun-çözüm", en: "problem-solution" },
    { tr: "acıt-ayaklandır-iyileştirme", en: "pain-agitate-relief" }
  ],
  [
    { tr: "facebook", en: "facebook" },
    { tr: "instagram", en: "instagram" },
    { tr: "pinterest", en: "pinterest" },
    { tr: "youtube", en: "youtube" },
    { tr: "linkedin", en: "linkedin" },
    { tr: "twitter", en: "twitter" },
    { tr: "tiktok", en: "tiktok" },
    { tr: "snapchat", en: "snapchat" },
    { tr: "web sitesi", en: "website" },
    { tr: "e-posta", en: "email" },
    { tr: "blog", en: "blog" },
    { tr: "podcast", en: "podcast" },
    { tr: "haber bülteni", en: "newsletter" },
    { tr: "uygulama", en: "app" }
  ],
  [
    { tr: "facebook", en: "facebook" },
    { tr: "instagram", en: "instagram" },
    { tr: "pinterest", en: "pinterest" },
    { tr: "youtube", en: "youtube" },
    { tr: "linkedin", en: "linkedin" },
    { tr: "twitter", en: "twitter" },
    { tr: "tiktok", en: "tiktok" },
    { tr: "snapchat", en: "snapchat" },
    { tr: "web sitesi", en: "website" },
    { tr: "e-posta", en: "email" },
    { tr: "blog", en: "blog" },
    { tr: "podcast", en: "podcast" },
    { tr: "haber bülteni", en: "newsletter" },
    { tr: "uygulama", en: "app" },
    { tr: "google display", en: "google display" }
  ],
  [
    { tr: "facebook", en: "facebook" },
    { tr: "instagram", en: "instagram" },
    { tr: "pinterest", en: "pinterest" },
    { tr: "youtube", en: "youtube" },
    { tr: "linkedin", en: "linkedin" },
    { tr: "twitter", en: "twitter" },
    { tr: "tiktok", en: "tiktok" },
    { tr: "snapchat", en: "snapchat" },
    { tr: "web sitesi", en: "website" },
    { tr: "e-posta", en: "email" },
    { tr: "blog", en: "blog" },
    { tr: "podcast", en: "podcast" },
    { tr: "haber bülteni", en: "newsletter" },
    { tr: "uygulama", en: "app" },
    { tr: "google display", en: "google display" }
  ],

  [
    { tr: "Türkçe", en: "Turkish" },
    { tr: "İngilizce", en: "English" },
    { tr: "İspanyolca", en: "Spanish" },
    { tr: "Fransızca", en: "French" },
    { tr: "Almanca", en: "German" },
    { tr: "İtalyanca", en: "Italian" },
    { tr: "Portekizce", en: "Portuguese" },
    { tr: "Rusça", en: "Russian" },
    { tr: "Çince", en: "Chinese" },
    { tr: "Japonca", en: "Japanese" },
    { tr: "Korece", en: "Korean" },
    { tr: "Arapça", en: "Arabic" },
    { tr: "Hintçe", en: "Hindi" },
    { tr: "Yunanca", en: "Greek" },
    { tr: "Ukraynaca", en: "Ukrainian" },
    { tr: "Lehçe", en: "Polish" },
    { tr: "Flemenkçe", en: "Dutch" },
    { tr: "Afrika Dili", en: "Africans" }
  ]
];

export const options = [
  [
    "comfort",
    "sustainable",
    "price-sensitive",
    "quality",
    "exclusivity",
    "lifestyle",
    "confidence",
    "innovation",
    "story-telling",
    "trend-setting",
    "emotional",
    "aspirational",
    "nostalgia",
    "experiental",
    "heritage",
    "functionality",
    "simplicity",
    "inclusivity"
  ],
  [
    "rahatlık",
    "sürdürülebilir",
    "fiyat duyarlı",
    "kalite",
    "eşsizlik,özgünlük",
    "yaşam tarzı",
    "güven",
    "yenilik",
    "hikaye anlatımı",
    "trend belirleyici",
    "duygusal",
    "arzu edilen",
    "nostalji",
    "deneyimsel",
    "miras",
    "fonksiyonellik",
    "basitlik",
    "kapsayıcılık"
  ],
  [
    "new year",
    "halloween",
    "valentines",
    "mothers-day",
    "fathers-day",
    "feast of ramadan",
    "feast of sacrifice",
    "hygiene day",
    "cyber-monday",
    "black-friday",
    "national sovereignty and children's day",
    "commemoration of Atatürk, youth and sports day",
    "victory day",
    "republic day"
  ],
  [
    "yılbaşı",
    "cadılar bayramı",
    "sevgililer günü",
    "anneler günü",
    "babalar günü",
    "ramazan bayramı",
    "kurban bayramı",
    "hijyen günü",
    "siber pazartesi",
    "black friday",
    "ulusal egemenlik ve çocuk bayramı",
    "Atatürk'ü anma, gençlik ve spor bayramı",
    "zafer bayramı",
    "cumhuriyet bayramı"
  ],
  [
    "free-gift-with-purchase",
    "bundle-offer",
    "limited-time-offer",
    "referal-bonus",
    "free-shipping",
    "buy-one-get-one",
    "free-trial",
    "abandonment-discount",
    "pre-order-discount",
    "gift-card",
    "loyalty-program",
    "seasonal-discount",
    "limited-edition",
    "flash-sale"
  ],

  [
    "satın alma ile birlikte hediye",
    "paket teklif",
    "sınırlı süreli teklif",
    "yönlendirme bonusu",
    "ücretsiz kargo",
    "bir alana bir alana",
    "ücretsiz deneme",
    "terk etme indirimi",
    "ön sipariş indirimi",
    "hediye kartı",
    "sadakat programı",
    "sezon indirimi",
    "sınırlı sayıda üretilen/özel seri",
    "hızlı satış/fırsat satışı"
  ],
  [
    "aida",
    "urgency-patience",
    "emotion-logic",
    "personal-universal",
    "consistent-contrasting",
    "story-solve-sell",
    "solution-savings-social-proof",
    "expectation-surprise",
    "exclusive-inclusive",
    "fomo",
    "friend-expert",
    "niche-differentiation",
    "social-proof",
    "past-present-future",
    "before-after",
    "unique-value-proposition",
    "problem-solution",
    "pain-agitate-relief"
  ],

  [
    "ilgilendirme-ilgilenme-isteklendirme-aksiyon alma(aida)",
    "aciliyet-sabır",
    "duygu-mantık",
    "kişisel-evrensel",
    "tutarlı-zıt",
    "hikaye-çözüm-satış",
    "çözüm-tasarruf-sosyal kanıt",
    "beklenti-sürpriz",
    "özel-kapsayıcı",
    "fırsat kaçırma endişesi(fomo)",
    "arkadaş-uzman",
    "niş-farklılaştırma",
    "sosyal kanıt",
    "geçmiş-şimdi-gelecek",
    "öncesi-sonrası",
    "benzersiz değer teklifi",
    "sorun-çözüm",
    "acıt-ayaklandır-iyileştirme"
  ],
  [
    "facebook",
    "instagram",
    "pinterest",
    "youtube",
    "linkedin",
    "twitter",
    "tiktok",
    "snapchat",
    "website",
    "email",
    "blog",
    "podcast",
    "newsletter",
    "app"
  ],
  [
    "facebook",
    "instagram",
    "pinterest",
    "youtube",
    "linkedin",
    "twitter",
    "tiktok",
    "snapchat",
    "web sitesi",
    "e-posta",
    "blog",
    "podcast",
    "haber bülteni",
    "uygulama"
  ],
  [
    "facebook",
    "instagram",
    "pinterest",
    "youtube",
    "linkedin",
    "twitter",
    "tiktok",
    "snapchat",
    "website",
    "email",
    "blog",
    "podcast",
    "newsletter",
    "app",
    "google display"
  ],
  [
    "facebook",
    "instagram",
    "pinterest",
    "youtube",
    "linkedin",
    "twitter",
    "tiktok",
    "snapchat",
    "web sitesi",
    "e-posta",
    "blog",
    "podcast",
    "haber bülteni",
    "uygulama",
    "google display"
  ]
];

export const languagesEN = [
  "Turkish",
  "English",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Portuguese",
  "Russian",
  "Chinese",
  "Japanese",
  "Korean",
  "Arabic",
  "Hindi",
  "Greek",
  "Ukrainian",
  "Polish",
  "Dutch",
  "Africans"
];

export const languagesTR = [
  "Türkçe",
  "İngilizce",
  "İspanyolca",
  "Fransızca",
  "Almanca",
  "İtalyanca",
  "Portekizce",
  "Rusça",
  "Çince",
  "Japonca",
  "Korece",
  "Arapça",
  "Hintçe",
  "Yunanca",
  "Ukraynaca",
  "Lehçe",
  "Flemenkçe",
  "Afrika Dili"
];
