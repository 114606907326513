import React, { useState } from "react";
import { contentTypes } from "../../data";
import ContentTypeTitle from "./ContentTypeTitle";
import FormInputs from "./FormInputs";
import UsersProducts from "./UsersProducts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
function ContentTypes() {
  const [selectedTitle, setSelectedTitle] = useState("");
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const usersProducts = useSelector((state:RootState)=> state.productListings.allProducts) 

  return (
    <div className="flex flex-col space-y-4">
      <div className="border border-slate-400 rounded-lg p-3 w-full">
        <h1 className="text-white text-3xl font-semibold border-slate-400 border-b-2 pb-2">
          {t("pages.home.promptTitles.mainTitle")}
        </h1>
        <div className="grid lg:grid-cols-6 gap-2 md:grid-cols-4 sm:grid-cols-2 ">
          {contentTypes.map((contentType) => (
            <ContentTypeTitle
              title={
                i18n.resolvedLanguage === "en"
                  ? contentType.nameEN
                  : contentType.nameTR
              }
              key={contentType.id}
              selectedTitle={selectedTitle}
              setSelectedTitle={setSelectedTitle}
            />
          ))}
        </div>
      </div>
      {(usersProducts?.length > 0 && selectedTitle) && <UsersProducts />}

      <div>
        <FormInputs selectedTitle={selectedTitle} />
      </div>
    </div>
  );
}

export default ContentTypes;
