import React from 'react'

interface PromptIdeaProps {
    title: string,
    idea: string
}

function PromptIdea({title,idea}:PromptIdeaProps) {
  return (
    <div className='flex flex-col space-y-3 p-3 bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-indigo-200 via-slate-600 to-indigo-200 rounded-lg '>
        <h1 className='text-lg font-semibold'>{title}</h1>
        <div className='bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg'>
            <p className='font-medium px-3 py-1'>{idea}</p>
        </div>
    </div>
  )
}

export default PromptIdea