import React from "react";
import { useTranslation } from "react-i18next";

function Information() {
  const {t,i18n} = useTranslation()
  const lng = navigator.language
  return (
    <div className="flex flex-col items-center mx-auto w-2/3 py-4 space-y-2">
      <h1 className="font-bold text-white text-lg sm:text-xl md:text-2xl lg:text-4xl text-center">
      {t("pages.home.inputField.header.title")}
      </h1>
      <h3 className="font-medium text-white text-base sm:text-lg md:text-xl text-center">
      {t("pages.home.inputField.header.description")}      </h3>
    </div>
  );
}

export default Information;
