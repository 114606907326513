import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function HelpCenter() {
  const prompter = useRef(null);
  const ideas = useRef(null);
  return (
    <div className=" min-h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black flex flex-col justify-between ">
      <Navbar prompter={prompter} ideas={ideas} />

      <Footer ideas={ideas} prompter={prompter} />
    </div>
  );
}

export default HelpCenter;
