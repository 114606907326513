import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface product {
  id: string;
  image: string;
  name: string;
  brandName: string;
  userId: string;
  category: string;
}

interface createProductProps {
  BASE_URL: string | undefined;
  token: string | undefined;
  productName: string;
  brandName: string;
  productCategory: string;
}

interface updateProductProps {
  BASE_URL: string | undefined;
  token: string | undefined;
  productName: string;
  brandName: string;
  productCategory: string;
  id: string;
}

interface deleteProductProps {
  BASE_URL: string | undefined;
  token: string | undefined;

  id: string;
}

interface productListingSliceState {
  allProducts: [product] | [] | any;
}

const initialState: productListingSliceState = {
  allProducts: []
};

export const getAllProducts = createAsyncThunk(
  "user/getAllProducts",
  async ({ token, BASE_URL }: any) => {
    const response = await axios.get(`${BASE_URL}/api/listings`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data.data.listings;
  }
);

export const createProduct = async ({
  BASE_URL,
  token,
  productName,
  brandName,
  productCategory
}: createProductProps) => {
  const response = await axios.post(
    `${BASE_URL}/api/listings`,
    {
      name: productName,
      brandName: brandName,
      category: productCategory
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const updateProduct = async ({
  BASE_URL,
  token,
  productName,
  brandName,
  productCategory,
  id
}: updateProductProps) => {
  const response = axios.patch(
    `${BASE_URL}/api/listings/${id}`,
    {
      name: productName,
      brandName: brandName,
      category: productCategory
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const deleteProduct = async ({
  BASE_URL,
  token,
  id
}: deleteProductProps) => {
  const response = axios.delete(`${BASE_URL}/api/listings/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const productListingSlice = createSlice({
  name: "productListing",
  initialState,
  reducers: {
    deleteProductFromArray: (state, { payload }) => {
      const newArray = state.allProducts.filter(
        (product: any) => product.id !== payload
      ) as [];
      state.allProducts = newArray;
    },
    addProductToArray: (state, { payload }) => {
      const newArray = [...state.allProducts, payload];
      state.allProducts = newArray;
    },
    resetArray: (state) => {
      state.allProducts = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, { payload }) => {
      state.allProducts = payload;
    });
  }
});

export const { deleteProductFromArray, addProductToArray, resetArray } =
  productListingSlice.actions;

export default productListingSlice.reducer;
