import React, { useState } from "react";
import UserProductInfo from "./UserProductInfo";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// const usersProducts = [
//   {
//     id: 1,
//     brandName: "Eczacıbaşı",
//     productName: "Selpak",
//     productCategory: "Kişisel Hijyen",
//   },
//   {
//     id: 2,
//     brandName: "Eczacıbaşı",
//     productName: "Defans",
//     productCategory: "Vücut Losyonu",
//   },
//   {
//     id: 3,
//     brandName: "Eczacıbaşı",
//     productName: "Selin",
//     productCategory: "Kozmetik",
//   },
//   {
//     id: 4,
//     brandName: "Eczacıbaşı",
//     productName: "Uni Baby",
//     productCategory: "Bebek Temizlik",
//   },
// ];

function UsersProducts() {
  const usersProducts = useSelector(
    (state: RootState) => state.productListings.allProducts
  );

  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  return (
    <div className="border border-slate-400 rounded-lg p-3 w-full">
      <h1 className="text-white text-xl font-semibold border-slate-400 border-b-2 pb-2">
        {t("pages.home.userProducts.mainTitle")}
      </h1>
      <div className="grid xl:grid-cols-4 gap-2 lg:grid-cols-3 sm:grid-cols-2 ">
        {usersProducts.map((product: any) => (
          <UserProductInfo
            key={product.id}
            id={product.id}
            brandName={product.brandName}
            productName={product.name}
            productCategory={product.category}
          />
        ))}
      </div>
    </div>
  );
}

export default UsersProducts;
